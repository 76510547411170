import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../components/globalComponents/Layout/Layout";
import HeroSectionSwiper from "../../components/HeroSectionSwiper/HeroSectionSwiper";
import theme from "../../theme";
import SearchInput from "../../components/globalComponents/global_inputs/SearchInput";
import { top100Films } from "../../components/data";
import {
  MarketAccessories,
  MarketCars,
  MarketHouse,
  MarketLands,
  MarketSocial,
  MarketVehicles,
} from "../../assets/MarkerHeroSection";
import Dropdown from "../../components/globalComponents/Dropdown";
import useFetch from "../../features/hooks/useFetch";
import { useDispatch } from "react-redux";
import { setProductParentCategories } from "../../features/slice/categoriesSlice";
import CategoryCard from "../../components/Market/Cards/CategoryCard";
import {
  digitalMarketing,
  google,
  graphicDesigner,
  growth,
  support,
  technology,
} from "../../assets/servicesCatergoriesIcons";
import Serviceimage from "../../assets/sample/Serviceimage.svg";
import ServicesCard from "../../components/Services/ServicesCard";

const Services = () => {
  const [flag, setFlag] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [productCategoriesList, setProductCategoriesList] = useState([]);
  const [parents, setParents] = useState(null);
  const { fetchData } = useFetch();
  let dispatch = useDispatch();

  let productCat = [];
  //function to fetch all products
  const getProductCategories = async () => {
    await fetchData("/api/product/parent", undefined, (res) => {
      if (res) {
        res?.data?.map((category) => {
          productCat.push(category.name);
        });
        setProductCategoriesList((current) => {
          current = productCat;
          return [...productCategoriesList, ...current];
        });
        setParents(res?.data);
        dispatch(setProductParentCategories({ data: res?.data }));
        setFlag(true);
      }
    });
  };
  useEffect(() => {
    getProductCategories();
  }, []);
  const marketCategories = [
    {
      name: "Tech & programming",
      image1: google,
      iconColor:
        "linear-gradient(180deg, #11669D 0%, #1AFBFB 50%, #E30256 100%)",
    },
    {
      name: "Design & Creative",
      image1: graphicDesigner,
      iconColor: "linear-gradient(180deg, #0AB3FF 0%, #4B65FF 100%)",
    },
    {
      name: "Digital Marketing",
      image1: digitalMarketing,
      iconColor:
        "linear-gradient(180deg, #4F9D11 0%, #1AFB8F 50%, #02E35C 100%)",
    },
    {
      name: "Telemarketing",
      image1: technology,
      iconColor: "linear-gradient(180deg, #9ABADB 0%, #ED3E3C 100%)",
    },
    {
      name: "Writing & Translation",
      image1: growth,
      iconColor: "linear-gradient(180deg, #9997CF 0%, #7E7BC6 100%)",
    },
    {
      name: "Business",
      image1: support,
      iconColor: "linear-gradient(180deg, #0AB3FF 0%, #4B65FF 100%)",
    },
  ];

  const marketSwiper = [
    {
      imagecourse: MarketAccessories,
    },
    {
      imagecourse: MarketHouse,
    },
    {
      imagecourse: MarketLands,
    },
    {
      imagecourse: MarketCars,
    },
    {
      imagecourse: MarketSocial,
    },
    {
      imagecourse: MarketVehicles,
    },
  ];

  const servicesData = [
    {
      id:"text-1",
      name: "Rao Muzamal",
      image1: Serviceimage,
      price: "5000",
      title: "Graphic Design",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      id: "text-2",
      name: "Rao Muzamal",
      image1: Serviceimage,
      price: "5000",
      title: "Graphic Design",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      id: "text-3",
      name: "Rao Muzamal",
      image1: Serviceimage,
      price: "5000",
      title: "Graphic Design",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      id: "text-4",
      name: "Rao Muzamal",
      image1: Serviceimage,
      price: "5000",
      title: "Graphic Design",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      id: "text-5",
      name: "Rao Muzamal",
      image1: Serviceimage,
      price: "5000",
      title: "Graphic Design",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      id: "text-6",
      name: "Rao Muzamal",
      image1: Serviceimage,
      price: "5000",
      title: "Graphic Design",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
  ];
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#4AEDDB1F",
          width: "100%",
          minHeight: "395px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Layout title={"Marketplace | SMAC"}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Grid md={6} item>
              <Box
                sx={{
                  display: "flex",
                  // alignItems:"center",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  gap: "25px",
                }}
              >
                <Typography
                  variant="h1"
                  style={{
                    color: "#14B8A6",
                  }}
                >
                  Discover Top Services in No Time
                </Typography>
                <Typography variant="paragraph">
                  Effortlessly buy and sell services on our marketplace, where
                  showcasing your offerings and discovering top solutions is
                  quick and simple.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    // width: "100%",
                    justifyContent: "start",
                    border: "1px solid grey",
                    background: "white",
                    borderRadius: "8px",
                  }}
                >
                  <Box
                    sx={{
                      width: "25%",
                    }}
                  >
                    {flag ? (
                      <Dropdown
                        name={"market"}
                        data={productCategoriesList}
                        cb={setSelectedCategory}
                        width={"100%"}
                      />
                    ) : null}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <SearchInput
                      dropDownData={top100Films}
                      inputType="multitag"
                      display={false}
                      width={true}
                      Icondisply={{
                        display: "none",
                      }}
                      margin={"0"}
                      area={true}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              md={5}
              sx={{
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              }}
            >
              <HeroSectionSwiper swiperData={marketSwiper} />
            </Grid>
          </Grid>
        </Layout>
      </Box>
      <Layout>
        {/* this is for the Services to show categore.... */}
        <Box
          sx={{
            marginTop: "55px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="topCategoriesHeading">
              Top Services Categories
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              marginTop: "30px",
              borderRadius: "32px",
              gap: "40px",
              "@media(max-width:910px)": {
                justifyContent: "center",
                gap: "8px",
              },
            }}
          >
            {marketCategories?.map((value, id) => {
              return <CategoryCard value={value} key={id} />;
            })}
          </Box>
        </Box>
      </Layout>
      <Layout>
        <Grid
          container
          spacing={10}
          sx={{ padding: "1rem", marginTop: "20px" }}
        >
          {servicesData
            ? servicesData?.map((data, id) => {
                return <ServicesCard key={id} value={data} />;
              })
            : "There is not Services"}
        </Grid>
      </Layout>
    </>
  );
};
export default Services;
