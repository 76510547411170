import React from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import DropDownIcons from "../../../../assets/DropDown.png";
import ProfilePicture from "../../../globalComponents/ProfilePicture";

const UserCard = ({ userDetails, handleStatus }) => {
  return (
    <TableRow>
      <TableCell>
        <ProfilePicture
          src={
            userDetails && userDetails.profilePic ? userDetails.profilePic : ""
          }
          firstName={userDetails.firstName}
          lastName={userDetails.lastName}
        />
      </TableCell>
      <TableCell>
        <Typography>Name</Typography>{" "}
        <Typography variant="h5BlackBold">
          {userDetails.firstName + " " + userDetails.lastName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>Email</Typography>
        <Typography variant="h5BlackBold">{userDetails.email}</Typography>
      </TableCell>
      <TableCell
        onClick={() => {
          console.log(userDetails, "Detailsss");
        }}
      >
        <Typography>Status</Typography>
        <Typography variant="h5BlackBold">
          {userDetails.isBlocked ? "Blocked" : "Active"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>Actions</Typography>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <IconButton variant="contained" {...bindTrigger(popupState)}>
                <img src={DropDownIcons} alt=" " />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={(e) => {
                    const data = {
                      userEmail: userDetails.email,
                      isBlocked: true,
                    };
                    handleStatus(data);
                  }}
                >
                  Block
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    const data = {
                      userEmail: userDetails.email,
                      isBlocked: false,
                    };
                    handleStatus(data);
                  }}
                >
                  UnBlock
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </TableCell>
    </TableRow>
  );
};

export default UserCard;
