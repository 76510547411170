import { Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import ButtonComp from "../../../../components/globalComponents/ButtonComp";
import SubscriptionCard from "../../../../components/DashBoard/AdminDashboard/cards/SubscriptionCard";

const SubscriptionTab = () => {
  const [isOpen, setIsOpen] = useState(false);

  let subscriptionData = [
    {
      plan_icon: "https://example.com/images/basic-plan.png",
      plan_name: "Basic Plan",
      plan_price: "$10/month",
      plan_benefits: [
        "Access to basic features",
        "Email support",
        "100 GB storage",
        "1 user license",
      ],
    },
    {
      plan_icon: "https://example.com/images/standard-plan.png",
      plan_name: "Standard Plan",
      plan_price: "$20/month",
      plan_benefits: [
        "Access to all features",
        "Priority email support",
        "1 TB storage",
        "5 user licenses",
        "1 TB storage",
        "5 user licenses",
      ],
    },
    {
      plan_icon: "https://example.com/images/premium-plan.png",
      plan_name: "Premium Plan",
      plan_price: "$50/month",
      plan_benefits: [
        "All features included",
        "24/7 phone support",
        "Unlimited storage",
        "Unlimited user licenses",
        "All features included",
        "24/7 phone support",
        "Unlimited storage",
        "Unlimited user licenses",
      ],
    },
  ];

  return (
    <Box
      sx={{
        // backgroundColor: "red",
        // padding: "15px",
        overflow: "hidden",
        width: "100%",
        background: "white",
      }}
    >
      <Box
        sx={{
          padding: "15px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component={"span"}
          variant="h2"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Subscriptions
        </Typography>
        <Box sx={{ width: "200px" }}>
          <ButtonComp
            label={"Add New Subscription"}
            click={() => setIsOpen(!isOpen)}
          />
        </Box>
      </Box>

      <Divider
        sx={{
          width: "100%",
        }}
      />
      <Box
        sx={{
          padding: "clamp(1.5rem, 1.739vw + 0.413rem, 2.5rem)",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          gap: "clamp(1rem, 0.87vw + 0.457rem, 1.5rem)",
        }}
      >
        {subscriptionData.map((plan, index) => {
          return <SubscriptionCard plan={plan} key={index} />;
        })}
      </Box>
    </Box>
  );
};

export default SubscriptionTab;
