import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import Divider from "@mui/material/Divider";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
// import ImageComp from "./ImageComp";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IoIosNotifications } from "react-icons/io";
import { IconButton, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ProfilePicture from "./ProfilePicture";
export default function NotificationsDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const handleClick = (event) => {
    // console.log(event, "event child Clicked");
    event.stopPropagation();
  };
  const toggleDrawer = (anchor, open) => (event) => {
    event.stopPropagation();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        borderTopLeftRadius: "16px",
        // position: "absolute",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          borderBottom: "1px solid #F1F1F1",
          paddingTop: "24px",
          position: "sticky",
          top: 0,//temporary top added
          backgroundColor: "white",
          zIndex: 999,
        }}
      >
        <Typography
          variant="bold24Black"
          sx={{ textAlign: "center", width: "100%" }}
        >
          Notifications
        </Typography>
      </Box>
      <Box>
        <List>
          {["1", "2", "3", "4", 5, 6, 7, 8, 9].map((text, index) => (
            <ListItem key={index}>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  paddingBottom: "15px",
                  width: "100%",
                }}
              >
                <Box>
                  {/* <ImageComp
                  src={"assets/photos/profile.png"}
                  alt={"story_image"}
                  sx={{
                    borderRadius: "50%",
                    height: "61px",
                    width: "61px",
                    backgroundSize: "cover",
                  }}
                /> */}
                  <ProfilePicture
                    // src={"assets/photos/profile.png"}
                    firstName={"ALishan"}
                    LastName={"urko"}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "230px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  {/* add user details */}
                  <Typography variant="postUserTypo" sx={{ fontSize: "16px" }}>
                    
                    {"alishan" + " " + "Finn"}
                  </Typography>
                  <Typography variant="subHeader" sx={{ fontSize: "14px" }}>
                    {
                      "Robert Fin Lorem Ipsum is simply dummy text of the printing."
                    }
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="subHeader"
                  sx={{ fontSize: "14px", wordBreak: "break-all" }}
                >
                  1hr
                </Typography>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  // aria-controls={open ? "long-menu" : undefined}
                  // aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(e) => handleClick(e)}
                  sx={{
                    isolation: "isolate",
                    // mixBlendMode:"difference",
                    color: "white",
                    backgroundColor: "black",
                    filter: "invert(1)",
                  }}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
  let anchor = "right";
  return (
    <Box sx={{position:"relative"}}>
      <IconButton
        size="small"
        edge="end"
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        onClick={toggleDrawer(anchor, true)}
        color="inherit"
        sx={{
          height:"55px",
          "&:hover": {
            color: "#14B8A6",
            // borderBottom: "1px solid #14B8A6",
            background: "transparent",
            // fill: theme.palette.primary.main,
          },
          display: "flex",
          flexDirection: "column",
          borderRadius: "0px",
          gap: "0px",
          margin: "0px 0px 0px 0px",
        }}
      >
        <IoIosNotifications size={"1.5em"} />
{/* 
        <Typography variant="body2" sx={{ fontSize: "16px" }}>
          Notifications
        </Typography> */}
      </IconButton>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        sx={{
        
          "&.MuiDrawer-modal":{
            // background:"yellow",
            // position:"relative"
          },
          "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
            width: "clamp(320px,100vw,400px)",
            // position: "fixed",
          },
          // these two classe are for live website do not change there if u see the height bug on local ".css-1ab2xsx" 
          "& .css-1ab2xsx":{
            top: "4rem !important", 
            bottom: "1rem !important",
            height: "auto !important", // Ensure auto height
            maxHeight: "95vh", // Add a maxHeight constraint
            borderLeft: "1px solid #B6B6B650",
            borderBottom: "1px solid #B6B6B650",
            borderTop: "1px solid #B6B6B650",
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
            position:"absolute"

          },
          "& .css-919eu4": {
            background: "none",

          },
        }}
      >
        {list(anchor)}
      </Drawer>
    </Box>
  );
}
