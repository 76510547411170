import React, { useRef } from "react";
import { Box } from "@mui/material";
import Layout from "../../components/globalComponents/Layout/Layout";
import HeroSection from "../../components/LandingPage/HeroSection";
import AboutSMAC from "../../components/LandingPage/AboutSMAC";
import WhyChooseSMAC from "../../components/LandingPage/WhyChooseSMAC";
import EmpoweringEverySkill from "../../components/LandingPage/EmpoweringEverySkill";
import Testmonials from "../../components/LandingPage/Testmonials";
import FAQs from "./../../components/LandingPage/FAQs";
import ContactSMAC from "../../components/LandingPage/ContactSMAC";
import PopularCourses from "../../components/LandingPage/PopularCourses";
import FeaturedCategories from "../../components/LandingPage/FeaturedCategories";
import OurPrograms from "../../components/LandingPage/OurPrograms";
import Footer from "../../components/globalComponents/footer/footer";
import { useSelector } from "react-redux";

const LandingPage = () => {
  const { user } = useSelector((state) => state.user);
  const aboutRef = useRef(null);
  const programsRef = useRef(null);
  const categoriesRef = useRef(null);
  const faqsRef = useRef(null);

  // Function to scroll to a section
  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Box sx={{ background: "#fbfbfb" }}>
      <HeroSection
        scrollToSection={scrollToSection}
        aboutRef={aboutRef}
        programsRef={programsRef}
        categoriesRef={categoriesRef}
        faqsRef={faqsRef}
      />
      <Layout>
        <div ref={aboutRef}>
          <AboutSMAC />
        </div>
        <div ref={programsRef}>
          <OurPrograms />
        </div>
        <div ref={categoriesRef}>
          <FeaturedCategories />
        </div>
        <EmpoweringEverySkill />
        <PopularCourses />
        <WhyChooseSMAC />
      </Layout>
      <Testmonials />
      <Layout>
        <div ref={faqsRef}>
          <FAQs />
        </div>
        <ContactSMAC />
      </Layout>
      {!user ? <Footer /> : null}
    </Box>
  );
};

export default LandingPage;
