import {
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import ImageComp from "../../../../components/globalComponents/ImageComp";
import useFetch from "../../../../features/hooks/useFetch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
const CourseListCard = ({ row, updateDataOnDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedCourseId, setSelectedCourseId] = React.useState(null);
  const { deleteData } = useFetch();
  const handleDeleteOptionClick = (course) => {
    setAnchorEl(null);
    deleteData(`/api/admin/course/${course.courseId}`, (res) => {
      //after deleting data filter current data and set updated courses data
      updateDataOnDelete(course.courseId);
    });
  };
  const handleDeleteIconClick = (event, courseId) => {
    setAnchorEl(event.currentTarget);
    // console.log(courseId, "course icon Click");
    setSelectedCourseId(courseId);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell scope="row">
        <ImageComp
          src={row.images[0]}
          sx={{
            width: "53px",
            height: "53px",
            borderRadius: "6px",
            objectFit: "cover",
          }}
          alt="Market"
        />
      </TableCell>
      <TableCell align="left">
        {" "}
        {/* product Name */}
        <Box>
          <Typography
            gutterBottom
            sx={{ display: "block", wordBreak: "break-all" }}
            component={"span"}
            variant="h6Grey"
          >
            Product Name
          </Typography>
          <Typography
            gutterBottom
            sx={{ display: "block", wordBreak: "break-all" }}
            component={"span"}
            variant="h5"
          >
            {row.title.length > 25
              ? row.title.substring(0, 25) + "....."
              : row.title}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="left">
        {/* seller Name */}
        <Box>
          <Typography
            gutterBottom
            sx={{ display: "block", wordBreak: "break-all" }}
            component={"span"}
            variant="h6Grey"
          >
            Instructor Name
          </Typography>
          <Typography
            gutterBottom
            sx={{ display: "block", wordBreak: "break-all" }}
            component={"span"}
            variant="h5"
          >
            {row?.user?.firstName + " " + row?.user?.lastName}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="left">
        {" "}
        {/* listed date */}
        <Box>
          <Typography
            gutterBottom
            sx={{ display: "block", wordBreak: "break-all" }}
            component={"span"}
            variant="h6Grey"
          >
            Listed Date
          </Typography>
          <Typography
            gutterBottom
            sx={{ display: "block", wordBreak: "break-all" }}
            component={"span"}
            variant="h5"
          >
            {row.createdAt}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="left">
        {" "}
        {/* status */}
        <Box>
          <Typography
            gutterBottom
            sx={{ display: "block", wordBreak: "break-all" }}
            component={"span"}
            variant="h6Grey"
          >
            Status
          </Typography>
          <Typography
            gutterBottom
            sx={{ display: "block", wordBreak: "break-all" }}
            component={"span"}
            variant="h5"
          >
            Listed
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="left">
        <IconButton
          sx={{
            margin: "5px 5px 0px 0px",
            isolation: "isolate",
            // mixBlendMode:"difference",
            color: "white",
            backgroundColor: "black",
            filter: "invert(1)",
          }}
          onClick={(event) => {
            event.stopPropagation();
            handleDeleteIconClick(event, row);
          }}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteOptionClick(selectedCourseId);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default CourseListCard;
